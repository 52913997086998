import React from "react"
// import { Spinner } from "reactstrap"

const loading = () => {
    return <div>
        {/* <Spinner /> */}
    </div>
}

export default loading
