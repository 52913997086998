import React, { Component } from "react"
import { Modal, ModalBody, ModalHeader, Row, Col, Button, Badge } from "reactstrap";
import Datetime from 'react-datetime';
import { CSVLink } from "react-csv";
import { injectIntl } from "gatsby-plugin-intl"
import { FaDownload } from "react-icons/all"
import { FormattedMessage } from "react-intl";
import axios from "../../api/axios"


class DownloadAirQualityReadings extends Component {

    state = {
        startDate: null,
        startDateObject: null,
        endDate: null,
        downloadData: [],
        noReadingsModal: false
    }

    toggleNoReadingsModal = () => {
        this.setState({
            noReadingsModal: !this.state.noReadingsModal
        });
    }

    csvLink = React.createRef()

    handleChangeStartDate = (date) => {
        var startDate = date._d.getFullYear() + "-" + (date._d.getMonth() + 1) + "-" + date._d.getDate()
        this.setState({ startDateObject: date._d, startDate: startDate })
    }

    handleChangeEndDate = (date) => {
        var endDate = date._d.getFullYear() + "-" + (date._d.getMonth() + 1) + "-" + date._d.getDate()
        this.setState({ endDate: endDate })
    }

    fetchDownloadData = () => {
        axios(this.props.intl.locale, `stations/readings/?start_date=${this.state.startDate}&end_date=${this.state.endDate}&station=${this.props.station.id}`).then(response => {
            if (response.data.length > 0) {
                const readingsWithoutPM25 = response.data.map(reading => {
                    reading['pm2.5'] = reading.pm25;
                    delete reading['pm25'];
                    Object.keys(reading).forEach(key => {
                        const value = reading[key];
                        delete reading[key];
                        reading[key.toUpperCase()] = value;
                    });
                    return reading;
                })
                this.setState({ downloadData: readingsWithoutPM25 }, () => {
                    this.csvLink.current.link.click();
                })
                this.setState({ startDate: null, endDate: null });
                this.props.toggleModal();
            }
            else {
                this.setState({
                    noReadingsModal: true
                });
            }
        });
    }

    isValidStartDate = (current) => {
        var today = Datetime.moment();
        return current.isBefore(today);
    }

    isValidEndDate = (current) => {
        var today = Datetime.moment();
        if (this.state.startDateObject === null)
            return current.isBefore(today);
        else
            return current.isAfter(this.state.startDateObject) && current.isBefore(today);
    }

    render() {
        const { intl, modalIsOpen, toggleModal, station } = this.props;
        return (
            <>
                <div onClick={toggleModal}
                    className={intl.locale === 'ar' ? 'download-text text-lg-left' : 'download-text text-lg-right'}
                    >
                    <FormattedMessage id="download_readings" />
                    <FaDownload className="m-2" />
                </div>

                <Modal isOpen={modalIsOpen} toggle={toggleModal} style={{ direction: intl.locale === 'ar' ? "rtl" : "ltr" }}>

                    <div className="modal-header">
                        <h5 className="modal-title">
                            <FormattedMessage id="download_readings" />
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModal}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col>
                                <p style={{ textAlign: intl.locale === 'ar' ? "right" : "left" }}>
                                    <FormattedMessage id="start_date" />
                                </p>
                                <Datetime
                                    timeFormat={false}
                                    onChange={this.handleChangeStartDate}
                                    isValidDate={this.isValidStartDate}
                                    dateFormat={"D-MM-YYYY"}
                                    inputProps={{
                                        placeholder: this.props.intl.formatMessage({ id: "pick_date" }),
                                        readOnly: true
                                    }}
                                />
                            </Col>

                            <Col>
                                <p style={{ textAlign: intl.locale === 'ar' ? "right" : "left" }}>
                                    <FormattedMessage id="end_date" />
                                </p>
                                <Datetime
                                    timeFormat={false}
                                    onChange={this.handleChangeEndDate}
                                    isValidDate={this.isValidEndDate}
                                    dateFormat={"D-MM-YYYY"}
                                    inputProps={{
                                        placeholder: this.props.intl.formatMessage({ id: "pick_date" }),
                                        readOnly: true
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="justify-content-center mt-4 mb-2">
                            <div>
                                <Button onClick={this.fetchDownloadData}
                                    disabled={this.state.startDate === null || this.state.endDate === null}
                                    className="btn btn-primary download-btn">
                                    <FormattedMessage id="download_readings" />
                                    <FaDownload className="m-2" />
                                </Button>
                                <CSVLink
                                    data={this.state.downloadData}
                                    filename={station.name + ".csv"}
                                    className="hidden"
                                    ref={this.csvLink}
                                    target="_blank"
                                />
                            </div>
                        </Row>
                    </div>
                    <Modal isOpen={this.state.noReadingsModal} toggle={this.toggleNoReadingsModal}>
                        <ModalHeader>
                            <h5>
                                <Badge color="danger" style={{paddingTop: "12px"}}>
                                    <FormattedMessage id="unavailable_reading" />
                                </Badge>
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.toggleNoReadingsModal}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </ModalHeader>
                        <ModalBody>
                            <h5><FormattedMessage id="no_readings_available" /></h5>
                        </ModalBody>
                    </Modal>
                </Modal>
            </>)
    }
}

export default injectIntl(DownloadAirQualityReadings)
