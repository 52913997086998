import "../assets/css/air_quality.css"
import React from "react"
import { connect } from "react-redux"
import { Container, Row, Col, Spinner } from "reactstrap"
import { FormattedMessage, injectIntl, navigate } from "gatsby-plugin-intl"
import Collection from "ol/Collection"
import Feature from "ol/Feature"
import { error } from "util"
import { throws } from "assert"
import Loadable from 'react-loadable';

import Loading from "../components/loading"
import SEO from "../components/seo"
import Layout from "../containers/Layout"
import axios from "../api/axios"
import MapService, { createSaticEmptyMap } from "../utils/mapService"
import AirElementButton from "../components/airQualityDetails/airElementButton"
import * as chartColors from "../shared/chartColors"
import ArabicLegendImg from "../images/airQuality/AirQualityDetailsLegend_ar.png"
import EnglishLegendImg from "../images/airQuality/AirQualityDetailsLegend_en.png"
import { hexToRgb, markerStyle } from '../utils'
import DownloadAirQualityReadings from "../components/airQualityDetails/downloadAirQualityReadings"
import * as actions from "../store/actions";


const AirElementColumnChart = Loadable({
  loader: () => import('../components/airQualityDetails/airElementColChart'),
  loading: Loading,
});

class AirQualityStationDetailsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stationLoading: true,
      regionLoading: true,
      readingsLoading: true,
      stationRegion: null,
      selectedStation: null,
      featureCollection: new Collection(),
      map: null,
      readingsDictionary: null,
      airElements: [{ name: "aqi", todaysValue: 0 },
      { name: "co", todaysValue: 0 },
      { name: "no2", todaysValue: 0 },
      { name: "o3", todaysValue: 0 },
      { name: "so2", todaysValue: 0 },
      // { name: "pm25", todaysValue: 0 },
      { name: "pm10", todaysValue: 0 },],
      selectedAirElement: "aqi",
      downloadReadingsModalIsOpen: false,
      downloadStartDate: null,
      downloadEndDate: null
    }
  }

  componentDidMount() {
    const { featureCollection } = this.state
    const map = createSaticEmptyMap();
    this.mapService = new MapService(map)
    this.fetchStation(this.props.intl.locale, this.getStationID())
    this.mapService.createVectorLayerFromCollection(featureCollection)
    map.setTarget(document.getElementById("map-div"))
    this.setState({ map: map })
  }

  componentDidUpdate(prevProps){
      const { selectedRegion, intl } = this.props;
      if (prevProps.selectedRegion && selectedRegion && (selectedRegion.id !== prevProps.selectedRegion.id)) {
        this.setState({
          readingsLoading: true
        });
        this.fetchUpdatedRegionStation(intl.locale, selectedRegion);
      }
  }

  fetchUpdatedRegionStation = async (locale, region) => {
    const regionParamPart = region.code === "GCC" ? "?is_default=true" : "?is_default=true&region=" + region.id
    const { data } = await axios(locale, "monitoringstations/" + regionParamPart)
    const updatedRegionStation = data[0];
    this.fetchStation(locale, updatedRegionStation.id);
    navigate(
      "/airQualityDetails/?country=" +
        region.code +
        "&station_id=" +
        updatedRegionStation.id
    )
  }

  getStationID = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const stationID = urlParams.get('station_id')
    if (!stationID) {
      throws(error("station_id param required"))
    }
    return stationID
  }

  resetFeatureCollection = () => {
    let { featureCollection } = this.state
    featureCollection.clear()
  }

  addStyleToFeature = features => {
    let { featureCollection } = this.state
    const markerStyle = this.createStationFeatureStyle()
    const styledFeatures = features.map(f => { f.setStyle(markerStyle); return f })
    this.resetFeatureCollection()
    if (styledFeatures && styledFeatures.length > 0) {
      featureCollection.extend(styledFeatures)
    }
  }

  fetchStation = (locale, stationID) => {
    axios(locale, `monitoringstations/${stationID}/`).then(response => {
      const data = response.data
      this.setState({ selectedStation: data, stationLoading: false }, () => {
        const feature = new Feature({
          name: data.name,
          geometry: this.mapService.getLocationGeom(data.location),
        })
        this.getRegionByID(this.props.intl.locale, data.region)
        this.fetchStationReadings(
          this.props.intl.locale, this.getStationID(),
          () => {
            this.addStyleToFeature([feature])
            this.mapService.zoomToPointLocation(this.state.selectedStation.location.coordinates, this.state.map)
            this.mapService.addZoomToExtentControl(this.state.selectedStation.location)
          }
        )
      })
    })
  }

  getOneMonthRange = () => {
    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    let yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 30)
    let sFormat = `${yesterday.getFullYear()}-${yesterday.getMonth() +
      1}-${yesterday.getDate()}`
    let eFormat = `${today.getFullYear()}-${mm}-${dd}`
    return [sFormat, eFormat]
  }

  formatEmptyReadings = () => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 30);
    let emptyReadings = [];
    
    for (let date = yesterday; date <= today; date.setDate(date.getDate() + 1)) {
      emptyReadings.push({
        "label": `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        "value": 0
      });
    };
    return emptyReadings;
  }

  getRegionByID = (locale, regionID) => {
    axios(locale, `regions/${regionID}/`).then(response => {
      const data = response.data
      this.props.setSelectedRegion(data.code)
      this.setState({ regionLoading: false, stationRegion: data })
      // this.mapService.zoomToLocation(data.location)
    })
  }

  getChartColorByValue = (value) => {
    var color = value === 0 ? chartColors.GRAY :
      value <= 50 ? chartColors.GREEN :
        value <= 100 ? chartColors.YELLOW :
          value <= 150 ? chartColors.ORANGE :
            value <= 200 ? chartColors.RED :
              value <= 300 ? chartColors.PURPLE :
                value <= 500 ? chartColors.BROWN : ""
    return color;
  }

  createStationFeatureStyle = () => {
    const name = this.state.selectedStation.name
    const reading = this.state.airElements.find(e => e.name === this.state.selectedAirElement).todaysValue
    const hexColor = this.getChartColorByValue(reading)
    const rgb = hexToRgb(hexColor)
    return markerStyle(rgb.asString(), name)
  }

  fetchStationReadings = (locale, stationID, callBack) => {
    let formats = this.getOneMonthRange()
    let sFormat = formats[0]
    let eFormat = formats[1]
    axios(locale, `stations/readings/?start_date=${sFormat}&end_date=${eFormat}&station=${stationID}`)
      .then(response => {
        // const aqiArray = [], coArray = [], no2Array = [], o3Array = [], so2Array = [], pm25Array = [], pm10Array = []
        const aqiArray = [], coArray = [], no2Array = [], o3Array = [], so2Array = [], pm10Array = []
        if (response.data.reverse().length > 0) {
          response.data.forEach(reading => {
            aqiArray.push({
              label: reading.reading_day,
              value: reading.aqi
            })
            coArray.push({
              label: reading.reading_day,
              value: reading.co
            })
            no2Array.push({
              label: reading.reading_day,
              value: reading.no2
            })
            o3Array.push({
              label: reading.reading_day,
              value: reading.o3
            })
            so2Array.push({
              label: reading.reading_day,
              value: reading.so2
            })
            // pm25Array.push({
            //   label: reading.reading_day,
            //   value: reading.pm25
            // })
            pm10Array.push({
              label: reading.reading_day,
              value: reading.pm10
            })
          })
          const readingsDictionary = {
            aqi: aqiArray,
            co: coArray,
            no2: no2Array,
            o3: o3Array,
            so2: so2Array,
            // pm25: pm25Array,
            pm10: pm10Array
          }
          const airElements = [
            { name: "aqi", todaysValue: aqiArray[aqiArray.length -1].value },
            { name: "co", todaysValue: coArray[coArray.length -1].value },
            { name: "no2", todaysValue: no2Array[no2Array.length -1].value },
            { name: "o3", todaysValue: o3Array[o3Array.length -1].value },
            { name: "so2", todaysValue: so2Array[so2Array.length -1].value },
            // { name: "pm25", todaysValue: pm25Array[pm25Array.length -1].value },
            { name: "pm10", todaysValue: pm10Array[pm10Array.length -1].value },
          ]
          this.setState({
            readingsLoading: false,
            readingsDictionary: readingsDictionary,
            airElements: airElements
          }, () => {
            if (callBack) callBack()
          })
        } else {
          this.setState({ 
            readingsLoading: false,
            readingsDictionary: null,
            airElements: [
              { name: "aqi", todaysValue: 0 },
              { name: "co", todaysValue: 0 },
              { name: "no2", todaysValue: 0 },
              { name: "o3", todaysValue: 0 },
              { name: "so2", todaysValue: 0 },
              // { name: "pm25", todaysValue: 0 },
              { name: "pm10", todaysValue: 0 },
            ],
          }, () => { if (callBack) callBack() })
        }
      })
  }

  handleAirElementClick = (airElement) => {
    const { featureCollection } = this.state
    this.setState({ selectedAirElement: airElement.name }, () => {
      this.addStyleToFeature(featureCollection.getArray())
    })
  }

  toggleDownloadModal = () => {
    this.setState({
      downloadReadingsModalIsOpen: !this.state.downloadReadingsModalIsOpen
    })
  }


  render() {
    const { readingsLoading, airElements, selectedAirElement, readingsDictionary } = this.state
    return (
      <Layout>
        <SEO title="Air Quality" />
        <div className="main">
          <div className="section justify-content-center">
            <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }} >
              <Container>
                <Row >
                {
                  this.state.selectedStation &&
                  <div id="aq-details-title" className="d-flex ">
                    <h4 className="mt-1 ml-1"> <FormattedMessage id="station_label" /></h4>
                    <h4 className="mt-1 mr-1">{this.state.selectedStation.name}</h4>
                  </div>
                }
                </Row>
              </Container>
            </div>

            <Container className="justify-content-center p-0">
              <div className="aq_main">
                {readingsLoading ? <Spinner />
                  : readingsDictionary === null ? <>
                  <Row>
                    <h3>
                      <FormattedMessage id="no_readings_available" />
                    </h3>
                  </Row>
                  <Row className="mt-1" style={{ width: "100%" }}>
                    {
                      airElements.map((airElement, i) =>
                        <Col key={i} lg={2} md={3} sm={6}  className="m-2 text-center">
                          <AirElementButton 
                            airElement={airElement}
                            onClick={() => this.handleAirElementClick(airElement)}
                            active={selectedAirElement === airElement.name}
                          />
                        </Col>
                      )
                    }
                  </Row>
                  <Row
                    className="justify-content-lg-end mt-4"
                    style={{ width: "100%" }}
                  >
                    <Col lg={6} md={12} sm={12}>
                      <DownloadAirQualityReadings
                        modalIsOpen={this.state.downloadReadingsModalIsOpen}
                        toggleModal={this.toggleDownloadModal}
                        station={this.state.selectedStation}
                      />
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row className="justify-content-center mt-3" style={{ width: "100%", direction: "ltr" }}>
                    <AirElementColumnChart
                      station={this.state.selectedStation}
                      airElement={selectedAirElement}
                      data={this.formatEmptyReadings()}
                      noValues="No" 
                    />
                  </Row>
                  <img src={this.props.intl.locale === "ar" ? ArabicLegendImg : EnglishLegendImg} width="75%" alt="img" />
                  <h5 style={{ fontWeight: "600" }}><FormattedMessage id="legend" /></h5>
                  </>
                    : <>

                      <Row  style={{ width: "100%" }}>
                        {airElements.map((airElement, i) =>
                          <Col key={i} lg={2} md={3} sm={6}  className="m-2 text-center">
                            <AirElementButton airElement={airElement}
                              onClick={() => this.handleAirElementClick(airElement)}
                              active={selectedAirElement === airElement.name} />
                          </Col>)}
                       
                      </Row>
                      <Row className="justify-content-lg-end mt-4" style={{ width: "100%" }}>
                         <Col lg={6} md={12}  sm={12}>
                          <DownloadAirQualityReadings
                            modalIsOpen={this.state.downloadReadingsModalIsOpen}
                            toggleModal={this.toggleDownloadModal}
                            station={this.state.selectedStation}
                          />
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row className="justify-content-center mt-3" style={{ width: "100%", direction: "ltr" }}>
                        <AirElementColumnChart
                          station={this.state.selectedStation}
                          airElement={selectedAirElement}
                          data={readingsDictionary[selectedAirElement]} />
                      </Row>
                      <img src={this.props.intl.locale === "ar" ? ArabicLegendImg : EnglishLegendImg} width="75%" alt="img" />
                      <h5 style={{ fontWeight: "600" }}><FormattedMessage id="legend" /></h5>
                    </>}

                <br />
                <br />
                <h4><FormattedMessage id="station_map_location" /></h4>
                <hr style={{
                  height: "3px",
                  width: "15vw",
                  background: "#2ac3ef",
                  marginBottom: "0px",
                }} />
                <div id="map-div" />
                {this.state.selectedStation && this.state.selectedStation.image && (
                  <div className="station-image-container">
                  <h4><FormattedMessage id="station_image" /></h4>
                  <hr style={{
                    height: "3px",
                    width: "10vw",
                    background: "#2ac3ef",
                    marginBottom: "30px",
                  }} />

                <img src={this.state.selectedStation.image} alt={this.state.selectedStation.name} className="img-thumbnail" />
                </div>
                )}

              </div>
            </Container>
          </div>
        </div>
      </Layout >
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedRegion: state.regions.selectedRegion,
    regions: state.regions.regions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedRegion: regionCode => dispatch(actions.setSelectedRegion(regionCode)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(injectIntl(AirQualityStationDetailsPage))
