import React from 'react';
import { Button } from 'reactstrap'
import classnames from "classnames"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"


class AirElementButton extends React.Component {

    render() {
        var valueLabel = null;
        var colorClassName = null;

        if (this.props.airElement.todaysValue === 0 || this.props.airElement.todaysValue === null) {
            colorClassName = "btn-black";
            valueLabel = this.props.intl.formatMessage({ id: "unavailable_reading" })
        } else if (this.props.airElement.todaysValue <= 50) {
            colorClassName = "btn-green";
            valueLabel = this.props.intl.formatMessage({ id: "very_good_reading" })
        } else if (this.props.airElement.todaysValue <= 100) {
            colorClassName = "btn-yellow";
            valueLabel = this.props.intl.formatMessage({ id: "good_reading" })
        } else if (this.props.airElement.todaysValue <= 150) {
            colorClassName = "btn-orange";
            valueLabel = this.props.intl.formatMessage({ id: "unhealthy_for_sensitive_reading" })
        } else if (this.props.airElement.todaysValue <= 200) {
            colorClassName = "btn-red";
            valueLabel = this.props.intl.formatMessage({ id: "unhealthy_reading" })
        } else if (this.props.airElement.todaysValue <= 300) {
            colorClassName = "btn-purple";
            valueLabel = this.props.intl.formatMessage({ id: "very_unhealthy_reading" })
        } else if (this.props.airElement.todaysValue <= 500) {
            colorClassName = "btn-brown";
            valueLabel = this.props.intl.formatMessage({ id: "hazardous_reading" })
        }

        return (
            <Button className={classnames("m-1", colorClassName)}
                onClick={this.props.onClick}
                style={this.props.active ? { width: "100%", minHeight: "155px" } : { width: "100%", height: "135px" }} >
                <p style={{ fontSize: "small" }}>
                    <FormattedMessage id={this.props.airElement.name} />
                </p>
                <FormattedMessage id="todays_value" />
                <br />
                {this.props.airElement.todaysValue ? this.props.airElement.todaysValue : 0}
                <br />
                <p style={{ fontWeight: "bold" }}>{this.props.active ? valueLabel : null}</p>
            </Button>
        );
    }
}

export default injectIntl(AirElementButton)
